<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon v-if="!isSameGroupAsActiveUser" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSameGroupAsActiveUser () {
      return this.$store.state.AppActiveUser.groups.includes(this.group['@id'])
    }
  },
  methods: {
    editRecord () {
      this.$router.push({'name': 'user-group-edit', 'params': { groupId: this.group.id }}).catch(() => {})
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('administration.grouplist.table.actions.delete.dialog.title'),
        text: this.$t('administration.grouplist.table.actions.delete.dialog.text', {username: this.group.username}),
        accept: this.deleteRecord,
        acceptText: this.$t('administration.grouplist.table.actions.delete.dialog.acceptText'),
        cancelText: this.$t('administration.grouplist.table.actions.delete.dialog.cancelText')
      })
    },
    deleteRecord () {
      this.$store.dispatch('group/removeGroup', this.group.id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       }) // eslint-disable-line no-console
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: this.$t('administration.grouplist.table.actions.delete.notify.title'),
        text: this.$t('administration.grouplist.table.actions.delete.notify.text')
      })
    }
  }
}
</script>
